<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('admin.products.tabs.general.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_h1.label')"
                        :label-for="'meta_h1.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_h1.' + language.code"
                          v-model="item.meta_h1[language.code]"
                          :state="errors && errors['meta_h1.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_h1.' + language.code]">
                          {{ errors['meta_h1.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_title.label')"
                        :label-for="'meta_title.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_title.' + language.code"
                          v-model="item.meta_title[language.code]"
                          :state="errors && errors['meta_title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_title.' + language.code]">
                          {{ errors['meta_title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_description.label')"
                        :label-for="'meta_description.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_description.' + language.code"
                          v-model="item.meta_description[language.code]"
                          :state="errors && errors['meta_description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_description.' + language.code]">
                          {{ errors['meta_description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_keywords.label')"
                        :label-for="'meta_keywords.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_keywords.' + language.code"
                          v-model="item.meta_keywords[language.code]"
                          :state="errors && errors['meta_keywords.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_keywords.' + language.code]">
                          {{ errors['meta_keywords.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.canonical.label')"
                        :label-for="'canonical.' + language.code"
                      >
                        <b-form-input
                          :id="'canonical.' + language.code"
                          v-model="item.canonical[language.code]"
                          :state="errors && errors['canonical.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['canonical.' + language.code]">
                          {{ errors['canonical.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.description.label')"
                        :label-for="'description.' + language.code"
                        :state="errors && errors['description.' + language.code] ? false : null"
                      >
                        <quill-editor
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          :options="editorOptions"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                          {{ errors['description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.data.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.slug.label')"
                    label-for="slug"
                  >
                    <b-form-input
                      id="slug"
                      v-model="item.slug"
                      :state="errors && errors.slug ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.slug">
                      {{ errors.slug[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.parent_id.label')"
                    label-for="parent_id"
                    :state="errors && errors.parent_id ? false : null"
                  >
                    <treeselect
                      id="parent_id"
                      v-model="item.parent_id"
                      :multiple="false"
                      :options="categoryOptions"
                      :normalizer="normalizer"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.parent_id">
                      {{ errors.parent_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    :label="$t('form.indexing_status.label')"
                    label-for="indexing_status"
                    :state="errors && errors.indexing_status ? false : null"
                  >
                    <v-select
                      id="indexing_status"
                      v-model="item.indexing_status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="indexing_status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.indexing_status">
                      {{ errors.indexing_status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <!-- media -->
                  <b-media no-body>
                    <b-media-aside>
                      <b-link>
                        <b-img
                          ref="previewEl"
                          rounded
                          :src="$options.filters.mediaUrl(item, 'avatar', '150x150')"
                          height="80"
                        />
                      </b-link>
                      <!--/ avatar -->

                      <!-- reset -->
                      <b-button
                        v-if="item.avatar"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="item.avatar = null"
                      >
                        {{ $t('general.reset') }}
                      </b-button>
                      <!--/ reset -->
                    </b-media-aside>

                    <b-media-body class="mt-75 ml-75">
                      <InitFileManager
                        field="avatar"
                        type="category_avatar"
                        :multiple="false"
                        @fm-selected="selectedAvatar"
                      />

                      <b-row
                        v-if="errors && errors.avatar"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors.avatar[0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="errors && errors['avatar.type']"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors['avatar.type'][0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>

                    </b-media-body>
                  </b-media>
                  <!--/ media -->
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.gcat_id.label')"
                    label-for="gcat_id"
                    :state="errors && errors.gcat_id ? false : null"
                  >
                    <v-select
                      id="gcat_id"
                      v-model="item.gcat_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="gcatOptions"
                      :reduce="val => val.value"
                      input-id="gcat_id"
                      :searchable="true"
                      @search="searchGcat"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.gcat_id">
                      {{ errors.gcat_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.has_filter.label')"
                    label-for="has_filter"
                    :state="errors && errors.has_filter ? false : null"
                  >
                    <v-select
                      id="has_filter"
                      v-model="item.has_filter"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="has_filter"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.has_filter">
                      {{ errors.has_filter[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.recommendation_attributes.label')"
                    label-for="recommendation_attributes"
                    :state="errors && errors.recommendation_attributes ? false : null"
                  >
                    <v-select
                      id="recommendation_attributes"
                      v-model="item.recommendation_attributes"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="uniqAttributes"
                      :reduce="val => val.value"
                      :clearable="true"
                      :multiple="true"
                      input-id="recommendation_attributes"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.recommendation_attributes">
                      {{ errors.recommendation_attributes[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.catalog_filter.title')">
              <div class="repeater-form">
                <b-row
                  v-for="(attribute, index) in item.attributes"
                  :id="'attribute' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.attribute_id.label')"
                      :label-for="'attributes.' + index + '.attribute_id'"
                      :state="errors && errors['attributes.' + index + '.attribute_id'] ? false : null"
                    >
                      <v-select
                        :id="'attributes.' + index + '.attribute_id'"
                        v-model="attribute.attribute_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="uniqAttributes"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'attributes.' + index + '.attribute_id'"
                        @search="searchAttribute"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.attribute_id']">
                        {{ errors['attributes.' + index + '.attribute_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      :label="$t('form.position.label')"
                      :label-for="'attributes.' + index + '.position'"
                    >
                      <b-form-input
                        :id="'attributes.' + index + '.position'"
                        v-model="attribute.position"
                        :state="errors && errors['attributes.' + index + '.position'] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.position']">
                        {{ errors['attributes.' + index + '.' + '.position'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-form-group
                      :label="$t('form.filterIsOpened.label')"
                      :label-for="'attributes.' + index + '.can_indexing'"
                    >
                      <b-form-checkbox
                        :id="'attributes.' + index + '.can_indexing'"
                        v-model="attribute.can_indexing"
                        :state="errors && errors['attributes.' + index + '.can_indexing'] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.can_indexing']">
                        {{ errors['attributes.' + index + '.' + '.can_indexing'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeAttribute(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addAttribute"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.attributes"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.attributes[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.navigation.extensions')">
              <b-row>
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group
                    :label="$t('form.addExtension.label')"
                    label-for="addExtensionId"
                  >
                    <v-select
                      id="addExtensionId"
                      v-model="addExtensionId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="extensionOptions"
                      :reduce="val => val.value"
                      input-id="addExtensionId"
                      :searchable="true"
                      @search="searchExtension"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-button
                    variant="primary"
                    class="mt-2"
                    block
                    @click="addExtension"
                  >
                    {{ $t('general.add') }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <div class="halo-tree mb-2">
                    <draggable
                      v-model="item.extensions"
                      group="tree"
                    >
                      <div
                        v-for="(extension, index) in item.extensions"
                        :key="extension.value"
                        class="li"
                      >
                        <div class="tree-node-el">
                          <div>
                            <span>{{ extension.label }}</span>
                          </div>
                          <div>
                            <button
                              class="btn btn-danger btn-sm"
                              @click="removeExtension(index)"
                            >
                              <feather-icon
                                icon="Trash2Icon"
                                size="14"
                                class-name="mr-50"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </draggable>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'product-categories-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import _ from 'lodash'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import GlobalMixin from '@/mixins/GlobalMixin'
import CategoryMixin from '@/mixins/CategoryMixin'
import EditorMixin from '@/mixins/EditorMixin'

export default {
  mixins: [GlobalMixin, CategoryMixin, EditorMixin],
  data() {
    return {
      item: null,
      languages: [],
      categoryOptions: [],
      gcatOptions: [],
      attributesOptions: [],
      attributesBaseOptions: [],
      attributesRecOptions: [],
      addExtensionId: null,
      extensionOptions: [],
      loading: {
        attributes: false,
      },
    }
  },
  computed: {
    uniqAttributes() {
      return _.uniqBy(_.concat(this.attributesBaseOptions, this.attributesRecOptions, this.attributesOptions), 'value')
    },
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get('/api/admin/categories', {
      params: {
        type: 'product',
      },
    })
      .then(response => {
        this.categoryOptions = response.data
      })

    await this.$http.get('/api/admin/extensions', {
      params: {
        per_page: 20,
      },
    })
      .then(response => {
        this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    await this.$http.get(`/api/admin/categories/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        if (!_.isNull(data.gcat) && !_.find(this.gcatOptions, { value: data.gcat.id })) {
          this.gcatOptions.push({
            value: data.gcat.id,
            label: data.gcat.ym_category_path,
          })
        }

        this.item = this.transformData(data)
        this.attributesBaseOptions = this.$options.filters.transformForVSelect(_.map(data.attributes, d => d.attribute), 'id', 'title')
        this.attributesRecOptions = this.$options.filters.transformForVSelect(_.map(data.recommendationAttributes, d => d.attribute), 'id', 'title')

        this.$route.meta.breadcrumb.pop()
        this.$route.meta.breadcrumb.push({ text: this.item.title.uk, active: true })
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'product-categories-index' })
        }
      })
    await this.loadAttributes()
  },
  methods: {
    loadAttributes() {
      if (Object.keys(this.attributesOptions).length) {
        return
      }

      this.loading.attributes = false

      this.$http.get('/api/attributes', {
        params: {
          sort_by: 'title', sort_desc: false, status: 'enabled', per_page: 20,
        },
      })
        .then(response => {
          this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
          this.loading.attributes = true
        })
        .catch(() => {
          this.loading.attributes = true
        })
    },
    async searchGcat(query) {
      await this.$http.get('/api/admin/gcats', {
        params: {
          query,
          type: 'product',
        },
      })
        .then(response => {
          this.gcatOptions = this.$options.filters.transformForVSelect(response.data, 'id', 'ym_category_path')
        })
    },
    selectedAvatar(data) {
      this.item.avatar = data
    },
    addAttribute() {
      this.item.attributes.push({
        attribute_id: null,
        attribute_value_id: null,
        position: Object.keys(this.item.attributes).length + 1,
        can_indexing: false,
      })
    },
    removeAttribute(index) {
      this.item.attributes.splice(index, 1)
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        slug: this.item.slug,
        type: 'product',
        parent_id: this.item.parent_id,
        avatar: this.item.avatar,
        status: this.item.status,
        indexing_status: this.item.indexing_status,
        has_filter: this.item.has_filter,
        description: this.item.description,
        meta_description: this.item.meta_description,
        meta_h1: this.item.meta_h1,
        meta_keywords: this.item.meta_keywords,
        meta_title: this.item.meta_title,
        canonical: this.item.canonical,
        gcat_id: this.item.gcat_id,
        attributes: this.item.attributes,
        recommendation_attributes: this.item.recommendation_attributes,
        extensions: _.map(this.item.extensions, ext => ext.value),
      }

      this.$http.put(`/api/admin/categories/${this.item.id}`, data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Збережено',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        slug: item.slug,
        gcat_id: item.gcat_id,
        avatar: this.$options.filters.singleMedia(item.avatar),
        parent_id: item.parent_id,
        status: item.status,
        indexing_status: item.indexing_status,
        has_filter: item.has_filter,
        title: {},
        description: {},
        meta_description: {},
        meta_h1: {},
        meta_keywords: {},
        meta_title: {},
        canonical: {},
        attributes: [],
        recommendation_attributes: [],
        extensions: this.$options.filters.transformForVSelect(item.extensions, 'id', 'title'),
      }

      if (!_.isNull(item.gcat)) {
        data.gcat_id = item.gcat.id
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.description[language.code] = translation ? translation.description : null
        data.meta_description[language.code] = translation ? translation.meta_description : null
        data.meta_h1[language.code] = translation ? translation.meta_h1 : null
        data.meta_keywords[language.code] = translation ? translation.meta_keywords : null
        data.meta_title[language.code] = translation ? translation.meta_title : null
        data.canonical[language.code] = translation ? translation.canonical : null
      })

      data.attributes = _.reduce(item.attributes, (result, attribute) => {
        result.push({
          id: attribute.id,
          attribute_id: attribute.attribute_id,
          attribute_value_id: attribute.attribute_value_id,
          position: attribute.position,
          can_indexing: attribute.can_indexing,
        })
        return result
      }, [])

      data.recommendation_attributes = _.map(item.recommendationAttributes, 'attribute_id')

      return data
    },
    async searchExtension(query) {
      await this.$http.get('/api/admin/extensions', {
        params: {
          query,
          per_page: 20,
        },
      })
        .then(response => {
          this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    addExtension() {
      this.item.extensions.push(_.find(this.extensionOptions, { value: this.addExtensionId }))
      this.addExtensionId = null
    },
    removeExtension(index) {
      this.item.extensions.splice(index, 1)
    },
    async searchAttribute(query) {
      await this.$http.get('/api/attributes', { params: { query, per_page: 20 } })
        .then(response => {
          this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
  },
}
</script>
